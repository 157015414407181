export function shuffle(array) {
  let currentIndex = array.length,
    tempoaryValue,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    tempoaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = tempoaryValue
  }

  return array
}
