import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, navigate } from 'gatsby'
import { shuffle } from '../util/shuffle'

const ImageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    display: flex;
    position: absolute;
    background-color: red;
    height: 100%;
  }
`

const MainImage = styled.img`
  height: 100%;
  width: 90%;
  object-fit: cover;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  background: red;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const HoverContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  flex: 1;
  &:hover {
    cursor: pointer;
  }
`

const ImageWrapper = styled.div`
  @media screen and (max-width: 768px) {
    height: auto;
    display: flex;
    width: 100vw;
    position: absolute;
    z-index: -1;
    height: 100%;
  }
`

const IndexPage = ({ data }) => {
  const contentfulVideos = shuffle(
    data.contentfulVIdeoList.videos
      .map((video) => {
        const vids = [
          {
            ...video,
            thumbnail: `${video.videoPreview.fixed.src}`,
          },
        ]
        if (video.screenshots) {
          console.log(video.screenshots)
          video.screenshots.map((screenshot) => {
            vids.push({
              ...video,
              thumbnail: `${screenshot.fixed.src}`,
            })
          })
        }

        return vids
      })
      .flat()
  )

  const [videos, setVideos] = useState(contentfulVideos)
  const [imagesPreloaded, setImagesPreloaded] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(videos[0])
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const [interval, setIntervalVal] = useState(null)
  const [dragged, setDrag] = useState(false)

  const startInterval = () => {
    if (!interval) {
      const imgInterval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * videos.length)
        setCurrentVideo(videos[randomIndex])
        setCurrentVideoIndex(randomIndex)
      }, 1500)
      setIntervalVal(imgInterval)
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      startInterval()
      return () => clearInterval(interval)
    }
  }, [])

  const preloadImages = async () => {
    const promises = videos.map((video) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = video.thumbnail
        img.onload = () => {
          video.img = img
          resolve(video)
        }
        return video
      })
    })

    const loadedVideos = await Promise.all(promises)
    setVideos(loadedVideos)
    setImagesPreloaded(true)
  }

  useEffect(() => {
    if (!imagesPreloaded) {
      preloadImages()
    }
  }, [imagesPreloaded, preloadImages])

  const handleDrag = (e) => {
    const percentage =
      (e.nativeEvent.pageX / document.body.offsetWidth) * videos.length
    const index = Math.floor(percentage)
    if (currentVideoIndex !== index) {
      setDrag(true)
    }
    setCurrentVideo(videos[index])
    setCurrentVideoIndex(index)
  }

  const handleMove = (e) => {
    const percentage = (e.clientX / document.body.offsetWidth) * videos.length
    const index = Math.floor(percentage)
    setCurrentVideo(videos[index])
    setCurrentVideoIndex(index)
  }

  const handleTouchEnd = (e) => {
    if (!dragged) {
      goToCategory()
    }
  }

  const goToCategory = (e) => {
    navigate(`/category/${currentVideo.category.slug}`, {
      state: {
        videoId: currentVideo.id,
      },
    })
  }

  return (
    <Layout>
      <SEO title="Home" />
      <ImageWrapper>
        {imagesPreloaded ? (
          <ImageContainer>
            {videos.map((video, index) => (
              <MainImage
                key={video.id}
                src={video.img.src}
                visible={index === currentVideoIndex}
              ></MainImage>
            ))}
          </ImageContainer>
        ) : (
          <div>Loading</div>
        )}
      </ImageWrapper>
      <HoverContainer
        onTouchStart={() => {
          setDrag(false)
          if (interval) {
            clearInterval(interval)
            setIntervalVal(null)
          }
        }}
        onTouchMove={handleDrag}
        onTouchEnd={handleTouchEnd}
        onMouseMove={handleMove}
        onClick={goToCategory}
      ></HoverContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query VideoQuery {
    contentfulVIdeoList(name: { eq: "HomePage" }) {
      videos {
        id
        category {
          title
          slug
        }
        title
        videoUrl
        videoPreview {
          fixed(width: 2000) {
            src
          }
        }
        screenshots {
          fixed(width: 2000) {
            src
          }
        }
      }
    }
  }
`

export default IndexPage
